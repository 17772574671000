import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { service } from "../../core/services";
import { Box, CircularProgress } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useSelector } from "react-redux";

// const paymentMethodsData = [
//   {
//     id: 1,
//     name: "Credit/Debit Card",
//     value: "stripe",
//   },
//   {
//     id: 2,
//     name: "Cash on delivery",
//     value: "cod",
//   },
// ];

const PaymentMethod = ({
  stateId,
  country,
  onConfirm,
  setSelectedPaymentMethod,
  selectedPaymentMethod,
}) => {
  const { t, i18n } = useTranslation();

  const [paymentMethodsData, setPaymentMethodsDta] = useState([]);
  const { cart, status, addToCartStatus } = useSelector((state) => state.cart);

  const [loading, setLoading] = useState(false);

  const fetchShiipingMethod = useCallback(async () => {
    try {
      setLoading(true);
      const res = await service.get(
        `/payment-methods?state_id=${stateId}&cart_uuid=${localStorage.getItem(
          "cart_uuid"
        )}`
      );
      setPaymentMethodsDta(res.data.data);
      setSelectedPaymentMethod(res.data.data[0]);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, [setSelectedPaymentMethod, stateId]);

  useEffect(() => {
    fetchShiipingMethod();
  }, [fetchShiipingMethod]);

  if (loading)
    return (
      <Box
        width={"100%"}
        height={200}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <CircularProgress style={{ color: "#000" }} size={30} />
      </Box>
    );

  return (
    <div className="" style={{ padding: 20 }}>
      <h2
        className="email-h2"
        style={{
          fontSize: 17,
          marginBottom: 40,
        }}
      >{t`Payment Methods`}</h2>

      <>
        {paymentMethodsData?.map((method) => (
          <>
            <Box
              display={"flex"}
              alignItems={"center"}
              pl={1}
              pr={1}
              style={{ cursor: "pointer" }}
              border={"1px solid #000"}
              mb={2}
              justifyContent={"space-between"}
              onClick={() => {
                setSelectedPaymentMethod(method);
              }}
            >
              <Box display={"flex"} alignItems={"center"} gap={1}>
                <Box
                  // mr={1}
                  border={"2px solid #000"}
                  width={30}
                  height={30}
                  borderRadius={60}
                  p={0.5}
                >
                  {selectedPaymentMethod?.id === method.id && (
                    <Box
                      bgcolor={"#000"}
                      borderRadius={60}
                      width={"100%"}
                      height={"100%"}
                    />
                  )}
                </Box>

                <Box display={"flex"} gap={1} alignItems={"center"}>
                  <p className="p-text">{method?.name}</p>
                  {method.id === 3 && (
                    <a
                      style={{ color: "inherit" }}
                      // type="button"
                      // data-tabby-info="installments"
                      // data-tabby-price="700"
                      // data-tabby-currency="AED"
                      // data-tabby-publicKey="pk_test_1c5a8f9e-60c3-4a5d-a014-09b45dea14"
                      // data-tabby-merchantCode="kyveli"
                      // onClick={() => {
                      //   console.log("clicked");
                      // }}
                      target="_blank"
                      rel="noreferrer"
                      href={`https://checkout.tabby.ai/promos/product-page/installments/${
                        i18n.language
                      }/?price=${
                        cart["price-after-discount"]
                        // cart["price-after-discount"].replace(/,/g, "")
                      }&currency=AED&public_key=pk_b0812961-a68a-436f-b94c-17ed9012845f`}
                    >
                      <InfoOutlinedIcon />
                    </a>
                  )}
                </Box>
              </Box>
              {/* <Box display={"flex"} alignItems={"center"} gap={2}> */}
              <img src={method?.image} alt="paymentImage" height={30} />
              {/* </Box> */}
            </Box>
          </>
        ))}
      </>

      <Box mt={4} display={"flex"} justifyContent={"flex-end"}>
        <button
          className="register-button"
          onClick={onConfirm}
          style={{ width: 200 }}
        >
          {t`Confirm`}
        </button>
      </Box>
    </div>
  );
};

export default PaymentMethod;
