import React, { useEffect, useMemo, useRef, useState } from "react";
import { Box, Button } from "@mui/material";
import styled from "styled-components";
import { FaPlus } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector } from "react-redux";

const variations = (product) => {
  const result = product?.variations?.filter(
    (value, index, self) =>
      index === self.findIndex((t) => t.color_hex === value.color_hex)
  );

  return result;
};

const Container = styled(Box)`
  cursor: pointer;
`;

const PlusIconContainer = styled(Box)`
  position: absolute;
  bottom: 20px;
  transform: translateX(-50%);
  left: 50%;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  min-width: 20px;
  border-radius: 60px;
  min-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.5);
  transition: color 0.1s ease-in-out;

  &:hover {
    color: rgba(0, 0, 0, 1);
  }
`;

const SelectVariations = styled(Box)`
  position: absolute;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  width: 100%;
  color: rgba(0, 0, 0, 0.5);
  transition: color 0.1s ease-in-out;

  &:hover {
    color: rgba(0, 0, 0, 1);
  }
`;

const SelectedColorSpan = styled.span`
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
  margin-left: 5px;
`;

const AddButton = styled(Button)`
  background: #000 !important;
  color: #fff !important;
  padding: 15px !important;
  width: 100% !important;
  &:hover {
    background: rgba(0, 0, 0, 0.7);
  }
`;

const PriceWithDiscountContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const LinedPrice = styled.p`
  padding: 0;
  margin: 0;
  font-size: 12px;
  text-decoration: line-through;

  @media (max-width: 450px) {
    font-size: 10px;
  }
`;

const DiscountedPrice = styled.p`
  padding: 0;
  margin: 0;
  color: red;
  font-size: 15px;
  font-weight: 600;

  @media (max-width: 450px) {
    font-size: 14px;
  }
`;

const DiscountPercentage = styled.div`
  padding: 1px;
  border: 1px solid red;
  font-size: 12px;
  color: red;
  border-radius: 5px;
  @media (max-width: 450px) {
    font-size: 10px;
  }
`;

const ProductCard = ({
  product,
  index,
  addToCart,
  onAddToCart,
  addToCartLoading,
  selectedItem,
  onSelectItem,
  handleSelectedProduct,
}) => {
  const imageRef = useRef();
  const [selectedColor, setSelectedColor] = useState("");
  const [selectedSize, setSelectedSize] = useState(null);
  const { companyInformation } = useSelector((state) => state.company);
  const { t, i18n } = useTranslation();

  const plusClicked = Boolean(selectedItem === product?.id);

  useEffect(() => {
    if (product) {
      const img = new Image();
      img.onload = () => {
        // imageRef.current.secondSrc = img;
        // this.setState({ imageIsReady: true });
      };
      img.src =
        // product?.images?.length > 1 ? product?.images[2] : product?.images[1]; // by setting an src, you trigger browser download
        product?.images[1];
      imageRef.current.secondSrc = img.src;
    }
  }, [product]);

  const sizes = useMemo(() => {
    return product?.variations?.filter((el) => el.color === selectedColor);
  }, [selectedColor, product]);

  // useEffect(() => {
  //   if (!sizes[0]?.size) {
  //     handleSelectedProduct(product?.variations[0]);
  //   }
  // }, [handleSelectedProduct, product?.variations, sizes]);

  return (
    <Container key={index}>
      <Box position={"relative"}>
        {addToCart && (
          <>
            {!plusClicked ? (
              <PlusIconContainer
                style={{
                  pointerEvents:
                    product?.variations?.length <= 0 ? "none" : "all",
                }}
                onClick={() => onSelectItem(product?.id)}
              >
                <FaPlus size={20} />
              </PlusIconContainer>
            ) : (
              <SelectVariations>
                <Box p={2}>
                  <p className="products-websitename">
                    {t`Select color`}{" "}
                    <SelectedColorSpan>{selectedColor}</SelectedColorSpan>
                  </p>
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    gap={0.6}
                    mt={2}
                    mb={5}
                  >
                    {variations(product)?.map((variation) => (
                      <div
                        onClick={() => {
                          setSelectedSize(null);
                          handleSelectedProduct(null);
                          if (!sizes[0]?.size) {
                            handleSelectedProduct(product?.variations[0].id);
                          }
                          setSelectedColor(variation?.color);
                        }}
                        style={{
                          width: "20px",
                          height: "20px",
                          border: "1px solid rgba(0,0,0, .2)",
                          backgroundColor: variation?.color_hex,
                          boxShadow:
                            selectedColor === variation?.color
                              ? `0 2px 5px 0 ${variation?.color_hex}`
                              : "none",
                        }}
                      />
                    ))}
                  </Box>
                  {sizes[0]?.size && (
                    <>
                      {selectedColor && (
                        <>
                          <p className="products-websitename">{t`Select size`}</p>
                          <Box
                            display={"flex"}
                            alignItems={"center"}
                            gap={0.6}
                            mt={-1}
                            ml={-1}
                          >
                            {sizes.map((variation) => (
                              <p
                                onClick={() => {
                                  setSelectedSize(variation.id);
                                  handleSelectedProduct(variation.id);
                                }}
                                style={{
                                  cursor: "pointer",
                                  border:
                                    selectedSize === variation.id
                                      ? "1px solid black"
                                      : "none",
                                }}
                                className="products-size"
                              >
                                {variation.size}
                              </p>
                            ))}
                          </Box>
                        </>
                      )}
                    </>
                  )}
                </Box>
                {selectedColor && (selectedSize || !sizes[0]?.size) && (
                  <AddButton
                    onClick={() =>
                      onAddToCart(
                        !sizes[0]?.size
                          ? product?.id
                          : sizes.filter((el) => el.id === selectedSize)[0]
                      )
                    }
                  >
                    {addToCartLoading ? (
                      <CircularProgress style={{ color: "#fff" }} size={20} />
                    ) : (
                      t`Add`
                    )}
                  </AddButton>
                )}
              </SelectVariations>
            )}
          </>
        )}

        <a
          href={`/products/product/${product.id}`}
          style={{
            pointerEvents: product?.variations?.length <= 0 ? "none" : "all",
            // position: "relative",
          }}
        >
          {product?.variations?.length <= 0 && (
            <div
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                display: "flex",
                background: "rgba(0,0,0,0.1)",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  background: "rgba(0, 0, 0, 0.6)",
                  borderRadius: "5px",
                  boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
                  backdropFilter: "blur(5px)",
                  border: "1px solid rgba(255, 255, 255, 0.3)",
                  padding: "10px",
                }}
              >
                <p style={{ padding: 0, margin: 0, color: "#fff" }}>SOLD OUT</p>
              </div>
            </div>
          )}
          <img
            ref={imageRef}
            src={
              // product.images.length > 1 ? product.images[1] : product.images[0]
              product.images[0]
            }
            onMouseOver={() =>
              (imageRef.current.src = imageRef?.current?.secondSrc)
            }
            onMouseOut={() =>
              // (imageRef.current.src =
              //   product.images.length > 1
              //     ? product.images[1]
              //     : product.images[0])
              (imageRef.current.src = product.images[0])
            }
            className="products-img"
            alt={`Product ${index}`}
          />
        </a>
      </Box>
      <div
        style={{
          pointerEvents: product?.variations?.length <= 0 ? "none" : "all",
        }}
      >
        <a
          style={{
            textDecoration: "none",
            color: "inherit",
          }}
          href={`/products/product/${product.id}`}
        >
          <p className="products-websitename-title">{product?.website_name}</p>
        </a>
      </div>
      {i18n.language === "en"
        ? product?.stock_en && (
            <p
              className="products-price"
              style={{ margin: 0, fontSize: 12, color: "red" }}
            >
              Available in {product?.stock_en}
            </p>
          )
        : product?.stock_ar && (
            <p
              className="products-price"
              style={{ margin: 0, fontSize: 12, color: "red" }}
            >
              متوفر في {product?.stock_ar}
            </p>
          )}

      {product?.price_with_discount === product?.price ||
      !product?.price_with_discount ? (
        <p className="products-price">
          {product?.price}{" "}
          {localStorage?.getItem("currency") ??
            companyInformation?.default_currency}
        </p>
      ) : (
        <PriceWithDiscountContainer>
          <LinedPrice>{product?.price}</LinedPrice>
          <DiscountedPrice>
            {product?.price_with_discount}{" "}
            {localStorage?.getItem("currency") ??
              companyInformation?.default_currency}
          </DiscountedPrice>
          <DiscountPercentage>
            -{product?.discount_percentage}%
          </DiscountPercentage>
        </PriceWithDiscountContainer>
      )}
      <p
        className="products-websitename"
        style={{
          color: "rgba(0,0,0,0.4)",
          fontSize: 12,
          fontWeight: 400,
          marginBottom: 10,
        }}
      >
        {i18n.language === "en" ? product?.note_en : product?.note_ar}
      </p>
      <Box display={"flex"} alignItems={"center"} gap={0.6}>
        {variations(product)?.map((variation) => (
          <div
            style={{
              width: "15px",
              height: "15px",
              border: "1px solid rgba(0,0,0, .2)",
              backgroundColor: variation?.color_hex,
            }}
          />
        ))}
      </Box>
    </Container>
  );
};

export default ProductCard;
