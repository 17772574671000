import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  addToCartByUuidAsync,
  decrementFromCartAsync,
  getCartAsync,
  removeFromCartAsync,
} from "../../core/slices/cartSlice";
import { useTheme } from "@mui/material/styles";
import {
  useMediaQuery,
  Box,
  IconButton,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { service } from "../../core/services";
import { Close } from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";

const OrderConfirmation = ({
  shippingMethod,
  personalInformation,
  paymentMethod,
  promoClicked,
  captchaToken,
  onEditClick,
  selectedPaymentMethod,
  promoCode,
  handleClosePromo,
  setPromoCode,
  selectedShippingMethod,
  setActiveStep,
  onPromoCodeButton,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { cart, status, addToCartStatus } = useSelector((state) => state.cart);
  const theme = useTheme();
  const [note, setNote] = useState("");
  const smallDevice = useMediaQuery(theme.breakpoints.down("sm"));
  const { companyInformation } = useSelector((state) => state.company);
  const [error, setError] = useState("");

  const [loading, setLoading] = useState(false);

  const handleIncrement = (item) => {
    dispatch(
      addToCartByUuidAsync(
        {
          product_id: item.product_id,
          quantity: 1,
          phone: localStorage.getItem("phoneNumber"),
        },
        () => {}
      )
    );
  };

  const handleRemove = (item) => {
    dispatch(removeFromCartAsync(item.id));
  };

  const handleDecrement = (item) => {
    if (item.quantity <= 1) {
      handleRemove(item);
      return;
    }
    dispatch(
      decrementFromCartAsync(
        {
          product_id: item.product_id,
          quantity: -1,
          phone: localStorage.getItem("phoneNumber"),
        },
        () => {}
      )
    );
  };

  const onFinish = async () => {
    console.log(selectedPaymentMethod);

    const req = {
      cart_uuid: localStorage.getItem("cart_uuid"),
      first_name: personalInformation.fname,
      last_name: personalInformation.lname,
      email: personalInformation.email,
      phone: personalInformation.phone,
      country: personalInformation.country.id,
      state: personalInformation.state.id,
      street: personalInformation.street,
      city: personalInformation.state.name,
      building: personalInformation.building,
      nearest: personalInformation.nearestLandmark,
      token: captchaToken,
      currency: "USD",
      apartment: personalInformation.apartment,
      shipping_rule_id: selectedShippingMethod.id,
      note: note,

      payment_channel:
        selectedPaymentMethod.id === 1
          ? "stripe"
          : selectedPaymentMethod.id === 2
          ? "COD"
          : "tabby",
    };

    if (promoClicked) {
      req.coupon_code = promoCode;
    }
    setLoading(true);
    try {
      const res = await service.post("store", req);
      console.log(res);
      const link = res.data.data.payment_link;
      if (!link) {
        window.location.href = `/thank-you?order-token=${res.data.data.order.token}`;
      } else {
        window.location.href = link;
        // window.open(
        //   link,
        //   "_blank" // <- This is what makes it open in a new window.
        // );
      }
    } catch (e) {
      if (e.response.status === 403) {
        console.log(e);
        setError(e.response.data.message);
      }
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    dispatch(
      getCartAsync(
        promoClicked ? promoCode : "",
        selectedShippingMethod?.price,
        personalInformation?.state?.id
      )
    );
  }, [dispatch, selectedShippingMethod?.price]);

  return (
    <div className="" style={{ padding: 20 }}>
      <Dialog
        open={Boolean(error)}
        onClose={() => setError("")}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t`Info`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {error}
          </DialogContentText>
        </DialogContent>
        {/* <DialogActions> */}
        <Box
          width={"100%"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <button
            className="register-button"
            style={{ width: 100 }}
            onClick={() => setError("")}
            disabled={loading}
          >
            {t`Done`}
          </button>
        </Box>
        {/* </DialogActions> */}
      </Dialog>
      <Box
        display={"flex"}
        alignItems={"center"}
        mb={"40px"}
        justifyContent={"space-between"}
      >
        <h2
          className="email-h2"
          style={{
            fontSize: 17,
            margin: 0,
            padding: 0,
          }}
        >{t`Your order summary`}</h2>
        {smallDevice && (
          <div
            style={{
              cursor: "pointer",
              borderBottom: "1px solid #000",
              fontWeight: 700,
              padding: 0,
              display: "inline-block",
            }}
            onClick={() => setActiveStep(1)}
          >
            {t`Edit`}
          </div>
        )}
      </Box>
      {cart?.carts?.map((item) => (
        <Box
          display={"flex"}
          gap={2}
          mb={1}
          flexDirection={"row"}
          //   alignItems={"flex-end"}
        >
          <img
            src={item?.image[0]}
            className="products-image"
            style={{ width: 90 }}
            alt={item.product_name}
          />
          <Box display={"flex"} flexDirection={"column"}>
            <Box mb={"auto"}>
              <p className="p-text-bold">{item.product_name}</p>
              <p className="p-text">
                {item.color}, {item.size}
              </p>
            </Box>
            <Box
              display={"flex"}
              alignItems={"center"}
              flexDirection={"row"}
              gap={1}
            >
              {/* <IconButton size="small" onClick={() => handleDecrement(item)}>
                {addToCartStatus === "loading" || status === "loading" ? (
                  <CircularProgress style={{ color: "#000" }} size={18} />
                ) : (
                  <FontAwesomeIcon icon={faMinus} />
                )}
              </IconButton> */}
              <p className="p-text">
                {t`Qty`}: {item.quantity}
              </p>
              {/* <IconButton size="small" onClick={() => handleIncrement(item)}>
                {addToCartStatus === "loading" || status === "loading" ? (
                  <CircularProgress style={{ color: "#000" }} size={18} />
                ) : (
                  <FontAwesomeIcon icon={faPlus} />
                )}
              </IconButton> */}
            </Box>
          </Box>
          <p
            className="p-text"
            style={{ marginLeft: "auto", marginTop: "auto" }}
          >
            {item.computed_price}{" "}
            {localStorage?.getItem("currency") ??
              companyInformation?.default_currency}
          </p>
        </Box>
      ))}
      <hr style={{ margin: "20px 0", color: "rgba(0,0,0,.4)" }} />

      {smallDevice ? (
        <>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <p
              className="p-text"
              style={{
                fontSize: 17,
              }}
            >{t`Subtotal`}</p>
            <p
              className="p-text"
              style={{
                fontSize: 17,
              }}
            >
              {localStorage?.getItem("currency") ??
                companyInformation?.default_currency}{" "}
              {cart?.sub_total}
            </p>
          </Box>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <p
              className="p-text"
              style={{
                fontSize: 17,
              }}
            >{t`Shipping Fee`}</p>
            <p
              className="p-text"
              style={{
                fontSize: 17,
              }}
            >
              {cart?.shipping_fees || 0}{" "}
              {localStorage?.getItem("currency") ??
                companyInformation?.default_currency}
            </p>
          </Box>

          {cart.tax !== 0 && (
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <p className="p-text">{t`Tax`}</p>
              <p className="p-text-bold">
                {cart.tax || 0}{" "}
                {localStorage?.getItem("currency") ??
                  companyInformation?.default_currency}
              </p>
            </Box>
          )}

          {cart.discount !== 0 && cart.discount && (
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <p className="p-text">{t`Discount`}</p>
              <p className="p-text-bold" style={{ color: "red" }}>
                -{cart.discount}{" "}
                {localStorage?.getItem("currency") ??
                  companyInformation?.default_currency}
              </p>
            </Box>
          )}

          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <p
              className="p-text-bold"
              style={{
                fontSize: 17,
              }}
            >{t`Total`}</p>
            <p
              className="p-text-bold"
              style={{
                fontSize: 17,
              }}
            >
              {localStorage?.getItem("currency") ??
                companyInformation?.default_currency}{" "}
              {cart["price-after-discount"]}
              {/* {cart?.sub_total + (shippingMethod?.price || 0)} */}
            </p>
          </Box>

          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <p className="p-text-bold">
              {promoClicked
                ? t`Promo code` + ": " + promoCode
                : t`Add promo code`}
            </p>
            {promoClicked && (
              <IconButton onClick={handleClosePromo}>
                <Close fontSize="small" />
              </IconButton>
            )}
          </Box>
          {!promoClicked && (
            <Box display={"flex"} alignItems={"center"} gap={1}>
              <input
                type="text"
                id="fname"
                onChange={(e) => setPromoCode(e.target.value)}
                name="promoCode"
                value={promoCode}
                placeholder={t`Promo code`}
                className="fname"
              />
              <button
                className="register-button"
                style={{ width: 100, margin: 0 }}
                onClick={onPromoCodeButton}
                disabled={status === "loading"}
              >
                {status === "loading" ? (
                  <CircularProgress size={20} style={{ color: "#fff" }} />
                ) : (
                  t`Apply`
                )}
              </button>
            </Box>
          )}
        </>
      ) : (
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <h2
            className="email-h2"
            style={{
              fontSize: 17,
            }}
          >{t`Your order summary`}</h2>
          <h2
            className="email-h2"
            style={{
              fontSize: 17,
            }}
          >
            {/* {cart?.sub_total + (shippingMethod?.price || 0)}{" "} */}
            {cart["price-after-discount"]}
            {localStorage?.getItem("currency") ??
              companyInformation?.default_currency}
          </h2>
        </Box>
      )}

      <hr
        style={{ marginTop: "40px", marginBottom: 40, color: "rgba(0,0,0,.4)" }}
      />

      <h2
        className="email-h2"
        style={{
          fontSize: 17,
          marginBottom: 40,
        }}
      >{t`Shipping and payment details`}</h2>
      <h2
        className="email-h2"
        style={{
          fontSize: 15,
          marginBottom: 20,
        }}
      >{t`Shipping address`}</h2>
      <p className="p-text">
        {personalInformation?.fname}, {personalInformation.lname}
      </p>
      <p className="p-text">
        {personalInformation?.district}, {personalInformation?.street} -{" "}
        {personalInformation?.country?.title},{" "}
        {personalInformation?.state?.name}
      </p>

      <div
        style={{
          cursor: "pointer",
          borderBottom: "1px solid #000",
          fontWeight: 700,
          padding: 0,
          display: "inline-block",
          marginBottom: 20,
          marginTop: 10,
        }}
        onClick={() => onEditClick(0)}
      >
        {t`Edit`}
      </div>

      <h2
        className="email-h2"
        style={{
          fontSize: 15,
          marginBottom: 20,
        }}
      >{t`Payment method`}</h2>
      <p className="p-text">{paymentMethod?.name}</p>

      <div
        style={{
          cursor: "pointer",
          borderBottom: "1px solid #000",
          fontWeight: 700,
          padding: 0,
          display: "inline-block",
          marginBottom: 20,
          marginTop: 10,
        }}
        onClick={() => onEditClick(2)}
      >
        {t`Edit`}
      </div>

      <h2
        className="email-h2"
        style={{
          fontSize: 15,
          marginBottom: 20,
        }}
      >{t`Shipping method`}</h2>
      <p className="p-text">{shippingMethod?.name}</p>

      <div
        style={{
          cursor: "pointer",
          borderBottom: "1px solid #000",
          fontWeight: 700,
          padding: 0,
          display: "inline-block",
          marginBottom: 20,
          marginTop: 10,
        }}
        onClick={() => onEditClick(1)}
      >
        {t`Edit`}
      </div>

      <div className="register-div-fname-lname">
        <input
          type="text"
          id="fname"
          onChange={(e) => setNote(e.target.value)}
          name="email"
          placeholder={t`Add note`}
          className="fname"
        />
      </div>
      <hr style={{ margin: "20px 0", color: "rgba(0,0,0,.4)" }} />
      <Box mt={4} display={"flex"} justifyContent={"flex-end"}>
        <button
          className="register-button"
          style={{ width: 250 }}
          onClick={onFinish}
          disabled={loading}
        >
          {loading ? <CircularProgress /> : t`Proceed with payment`}
        </button>
      </Box>
    </div>
  );
};

export default OrderConfirmation;
