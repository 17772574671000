import React, { useState, useEffect } from "react";
import axios from "axios";
import "../Section2/section2.css";
import { useTranslation } from "react-i18next";
import { service } from "../../../../core/services";

function SectionFive() {
  const [sectionFive, setSectionFive] = useState({});
  const { i18n } = useTranslation();

  useEffect(() => {
    const fetchSectionThree = async () => {
      try {
        const response = await service.get("sectionfive", {
          headers: {
            "Accept-Language": i18n.language || "en",
          },
        });
        setSectionFive(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchSectionThree();
  }, [i18n.language]);

  const handleItemClick = (id) => {
    window.location.href = `/products/${id}`;
  };
  return (
    <div className="sectionone">
      <div className="">
        {sectionFive && (
          <div className="section1-image-name">
            {sectionFive.image && (
              <a href={`/products/${sectionFive.id}`} className="a-sections">
                <p className="section1-name">{sectionFive.name}</p>
                <a
                  href={`/products/${sectionFive.id}`}
                  className="section__discover-btn"
                >
                  <span>Discover</span>
                </a>
                <img
                  src={sectionFive.image}
                  alt={sectionFive.name}
                  className="section1-image"
                />
              </a>
            )}
            {sectionFive.video && (
              <a href={`/products/${sectionFive.id}`} className="a-sections">
                <p className="section1-name">{sectionFive.name}</p>
                <video playsInline autoPlay loop muted className="banner-video">
                  <source src={sectionFive.video} type="video/mp4" />
                </video>
              </a>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default SectionFive;
