import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Box,
  Grid,
  Modal,
  Button,
  Container,
  CircularProgress,
  TextField,
  Typography,
  DialogTitle,
  Dialog,
  Drawer,
  DialogContent,
  Autocomplete,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import axios from "axios";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { FaTimes, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import ProductCard from "../ProductPage/ProductCard";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { addToCartByUuidAsync } from "../../core/slices/cartSlice";
import { IoMdDownload } from "react-icons/io";
import { download } from "../../utils/download";
import { countries } from "countries-list";
import { useAuth } from "../../hooks/useAuth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { service } from "../../core/services";
import SizeGuide from "./Size Guide/sizeguide";
import SizeGuideShoes from "./SizeGuideShoes/sizeguideshoes";
import SizeGuideINT from "./Size Guide INT/sizeguideINT";
import { MapInteractionCSS } from "react-map-interaction";
import QuickPinchZoom, { make3dTransformValue } from "react-quick-pinch-zoom";
import TabbyLogo from "../../Assets/tabby.png";

const countriesArrWithIs = Object.keys(countries).map((country) => ({
  ...countries[country],
  code: country,
}));

const countriesArr = countriesArrWithIs.filter(
  (el) => el.name.toLowerCase() !== "israel"
);

const variations = (product) => {
  if (!product) return null;
  const result = product?.filter(
    (value, index, self) =>
      index === self.findIndex((t) => t.color_hex === value.color_hex)
  );

  return result;
};

const ImagesContainer = styled(Box)`
  max-height: 100vh;
  overflow-y: scroll;
  cursor: pointer;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  scroll-behavior: smooth;
  position: relative;

  -khtml-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: row;
  }
`;

const LighText = styled.p`
  font-size: 16px;
  color: rgba(0, 0, 0, 0.5);
`;

const MoreText = styled.p`
  font-weight: 800;
  font-size: 20px;
  margin-top: 60px;
`;

const ModalContainer = styled(Box)`
  background-color: rgba(0, 0, 0, 0.8);
  -khtml-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
`;

const AddButton = styled(Button)`
  background: #000 !important;
  color: #fff !important;
  padding: 15px !important;
  border-radius: 0 !important;
  width: 100%;
  font-weight: 800 !important;
`;

const DetailsButton = styled(Button)`
  background: transparent !important;
  color: #000 !important;
  padding: 15px !important;
  font-size: 14px !important;
  border-radius: 0 !important;
  text-transform: none !important;
  border-bottom: 1px solid #000 !important;
  padding-bottom: 1px !important;
`;

const SelectVariations = styled(Box)`
  background-color: rgba(255, 255, 255, 0.8);
  width: 100%;
  transition: color 0.1s ease-in-out;
`;

const SelectedColorSpan = styled.span`
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
  margin-left: 5px;
`;

const DotsContainer = styled(Box)`
  position: absolute;
  z-index: 100;
  left: 30px;
  bottom: 30px;
  display: flex;
  gap: 10px;
  flex-direction: column;

  @media (max-width: 768px) {
    flex-direction: row;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const Dot = styled(Box)`
  width: 7px;
  height: 7px;
  border: 1px solid #000;
  border-radius: 60px;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  &:is(.active) {
    background-color: #000;
  }
`;

const PriceWithDiscountContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 10px;
`;

const LinedPrice = styled.p`
  padding: 0;
  margin: 0;
  font-size: 12px;
  text-decoration: line-through;
`;

const DiscountedPrice = styled.p`
  padding: 0;
  margin: 0;
  color: red;
  font-size: 16px;
  font-weight: 600;
`;

const DiscountPercentage = styled.div`
  padding: 1px;
  border: 1px solid red;
  font-size: 12px;
  color: red;
  border-radius: 5px;
`;

const SingleProductPage = () => {
  const { productId } = useParams();
  const [data, setData] = useState({});
  const [isAddContactOpen, setAddContact] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedColor, setSelectedColor] = useState("");
  const [selectedSize, setSelectedSize] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [indexImage, setIndexImage] = useState(0);
  const { addToCartStatus } = useSelector((state) => state.cart);
  const [isCountryOpen, setCountryOpen] = useState(false);
  const imageContainerRef = useRef();
  const { companyInformation } = useSelector((state) => state.company);
  const [selectedCountry, setSelectedCountry] = useState({
    name: "Lebanon",
    native: "لبنان",
    phone: [961],
    continent: "AS",
    capital: "Beirut",
    currency: ["LBP"],
    languages: ["ar", "fr"],
    code: "LB",
  });

  useEffect(() => {
    if (companyInformation?.currency === "AED") {
      setSelectedCountry({
        name: "United Arab Emirates",
        native: "دولة الإمارات العربية المتحدة",
        phone: [971],
        continent: "AS",
        capital: "Abu Dhabi",
        currency: ["AED"],
        languages: ["ar"],
        code: "AE",
      });
    }
  }, [companyInformation?.currency]);

  const [openDetails, setOpenDetails] = useState(false);
  const [sizeGuideOpen, setSizeGuideOpen] = useState(false);
  const [internationalZizeGuideOpen, setInternationalZizeGuideOpen] =
    useState(false);
  const [shoesZizeGuideOpen, setShoesZizeGuideOpen] = useState(false);
  const [SKU, setSKU] = useState(null);
  const { accessToken } = useAuth();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const mediumDevice = useMediaQuery(theme.breakpoints.up("md"));

  const sizes = useMemo(() => {
    return data?.Products?.filter((el) => el.color === selectedColor);
  }, [data?.Products, selectedColor]);

  const fetchProduct = useCallback(async () => {
    try {
      setLoading(true);
      const response = await service.get(`variations/${productId}`, {
        headers: {
          "Accept-Language": i18n.language || "en",
        },
      });
      setLoading(false);
      // response?.data?.data?.Parent?.images.shift();
      setData(response?.data?.data);
    } catch (e) {
      console.log(e);
    }

    setLoading(false);
  }, [i18n.language, productId]);

  useEffect(() => {
    fetchProduct();
  }, [fetchProduct]);

  const images = useMemo(() => {
    if (selectedColor) {
      const filtered = data?.Products?.filter(
        (el) => selectedColor === el.color
      );

      return filtered.length > 0 ? filtered[0].images : [];
    } else {
      return data?.Parent?.images;
    }
  }, [data?.Parent?.images, data?.Products, selectedColor]);

  const addToCart = async () => {
    dispatch(
      addToCartByUuidAsync(
        {
          product_id: selectedSize,
          quantity: 1,
          phone: localStorage.getItem("phoneNumber"),
        },
        () => {}
      )
    );
  };

  const handleAddToCart = () => {
    if (!accessToken) {
      const phoneNumber = localStorage.getItem("phoneNumber");
      if (!phoneNumber) {
        setAddContact(true);
      } else {
        addToCart();
      }
    } else {
      addToCart();
    }
  };

  useEffect(() => {
    const s = document.createElement("script");
    s.type = "text/javascript";
    const lang = i18n.language ?? "en";
    s.async = true;
    if (lang === "en") {
      s.innerHTML = `
      new TabbyPromo({
     selector: "#TabbyPromo", // required, content of tabby Promo Snippet will be placed in element with that selector.
     currency: "AED", // required, AED|SAR|KWD only supported, with no spaces or lowercase.
     price: ${data?.Parent?.price_with_discount.replace(
       /,/g,
       ""
     )}, // required, price of the product. 2 decimals max for AED|SAR and 3 decimals max for KWD.
     lang: "en", // Optional, en|ar only supported
     source: "product",
     publicKey: "pk_b0812961-a68a-436f-b94c-17ed9012845f", // required, Public Key
     merchantCode: "kyveli", // required
   });
 `;
    } else {
      s.innerHTML = `
      new TabbyPromo({
     selector: "#TabbyPromo", // required, content of tabby Promo Snippet will be placed in element with that selector.
     currency: "AED", // required, AED|SAR|KWD only supported, with no spaces or lowercase.
     price: ${data?.Parent?.price_with_discount.replace(
       /,/g,
       ""
     )}, // required, price of the product. 2 decimals max for AED|SAR and 3 decimals max for KWD.
     lang: "ar", // Optional, en|ar only supported
     source: "product",
     publicKey: "pk_test_1c5a8f9e-60c3-4a5d-a014-09b45dea14", // required, Public Key
     merchantCode: "kyveli", // required
   });
 `;
    }

    document.body.appendChild(s);
    // this.instance.appendChild(s);
  }, [data?.Parent?.price_with_discount, i18n.language]);

  const productDetails = useMemo(() => {
    return data?.Parent?.content?.split(".");
  }, [data?.Parent?.content]);

  return (
    <>
      <SizeGuide
        sizeOpen={sizeGuideOpen}
        onClose={() => setSizeGuideOpen(false)}
      />
      <SizeGuideShoes
        sizeOpen={shoesZizeGuideOpen}
        onClose={() => setShoesZizeGuideOpen(false)}
      />
      <SizeGuideINT
        sizeOpen={internationalZizeGuideOpen}
        onClose={() => setInternationalZizeGuideOpen(false)}
      />
      <Dialog
        open={isCountryOpen}
        onClose={() => setCountryOpen(false)}
        fullWidth
        maxWidth="lg"
      >
        <DialogContent style={{ height: "100vh" }}>
          <Autocomplete
            disablePortal
            open
            options={countriesArr}
            fullWidth
            onChange={(even, value) => {
              setSelectedCountry(value);
              setCountryOpen(false);
            }}
            getOptionLabel={(option) => option.native}
            autoHighlight
            style={{ border: "100%" }}
            isOptionEqualToValue={(option, value) =>
              option?.name === value?.name
            }
            filterOptions={(options, { inputValue }) =>
              options.filter((el) => {
                return (el.name + el.native).toLowerCase().includes(inputValue);
              })
            }
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
                gap={1}
              >
                <img
                  loading="lazy"
                  width="20"
                  srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                  src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                  alt=""
                />
                {option.native} ({option.phone})
              </Box>
            )}
            renderInput={(params) => (
              <TextField style={{ border: "none" }} {...params} />
            )}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={isAddContactOpen}
        onClose={() => setAddContact(false)}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle
          textAlign={"center"}
          fontSize={14}
        >{t`Please enter your phone number`}</DialogTitle>
        <Box
          display={"flex"}
          alignItems={"center"}
          p={2}
          gap={1}
          flexDirection={"row"}
        >
          <Button color="inherit" onClick={() => setCountryOpen(true)}>
            <Box display={"flex"} gap={1} alignItems={"center"}>
              <img
                loading="lazy"
                width="20"
                srcSet={`https://flagcdn.com/w40/${selectedCountry?.code?.toLowerCase()}.png 2x`}
                src={`https://flagcdn.com/w20/${selectedCountry?.code?.toLowerCase()}.png`}
                alt=""
              />
              <Typography>+{selectedCountry?.phone}</Typography>
            </Box>
          </Button>
          <TextField
            fullWidth
            type="text"
            id="phone_number"
            name="phone_number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            placeholder={`${t`Phone number`} *`}
            required
          />
        </Box>
        <AddButton
          onClick={() => {
            localStorage.setItem(
              "phoneNumber",
              selectedCountry.phone + phoneNumber
            );
            handleAddToCart();
            setAddContact(false);
          }}
        >{t`Add`}</AddButton>
      </Dialog>
      <Drawer
        open={openDetails}
        onClose={() => setOpenDetails(false)}
        anchor="right"
      >
        <Box
          p={5}
          width={mediumDevice ? 600 : "100vw"}
          display={"flex"}
          flexDirection={"column"}
          flex={1}
          justifyContent={"space-between"}
        >
          <Box>
            <Box
              display="flex"
              flexDirection={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <p className="p-text-bold" style={{ fontSize: 18 }}>
                {t`Product Details`}
              </p>
              <IconButton size="small" onClick={() => setOpenDetails(false)}>
                <FontAwesomeIcon icon={faClose} />
              </IconButton>
            </Box>

            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={0.5}
              border={"1px solid #000"}
              p={2}
            >
              {productDetails?.map((text) => (
                <p className="p-text" style={{ margin: 0 }}>
                  {text}
                </p>
              ))}
            </Box>
          </Box>
          <Box>
            {/* <Box display={"flex"} justifyContent={"space-between"}>
            <p className="p-text-bold">Subtotal</p>
            <p className="p-text-bold">${cart?.sub_total}</p>
          </Box> */}
          </Box>
        </Box>
      </Drawer>
      <Modal
        // fullScreen
        open={selectedImage}
        onClose={() => setSelectedImage(null)}
        style={{ zIndex: 30400430504 }}
      >
        <ModalContainer
          position={"relative"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <div
            onClick={() => download(images[selectedImage - 1])}
            style={{
              color: "#fff",
              cursor: "pointer",
              position: "absolute",
              marginLeft: "auto",
              zIndex: 100,
              left: 20,
              top: 20,
            }}
          >
            <IoMdDownload size={36} />
          </div>
          <FaTimes
            style={{
              position: "absolute",
              right: 20,
              top: 20,
              color: "red",
              zIndex: 1000,
              cursor: "pointer",
            }}
            onClick={() => setSelectedImage(null)}
            size={30}
          />
          <div
            onClick={() => {
              if (selectedImage > 1) {
                setSelectedImage(selectedImage - 1);
              }
            }}
            style={{
              color: "#fff",
              cursor: "pointer",
              position: "absolute",
              marginLeft: "auto",
              zIndex: 100,
              left: 10,
            }}
          >
            <FaChevronLeft size={30} />
          </div>
          {/* <QuickPinchZoom
            onUpdate={() => {
              console.log("update");
            }}
            tapZoomFactor={2}
            zoomOutFactor={4}
            inertiaFriction={0}
            maxZoom={10}
            minZoom={1}
          > */}
          <RenderInsideImage src={images && images[selectedImage - 1]} />
          {/* <img
            src={images && images[selectedImage - 1]}
            alt={``}
            // className="products-img"

            style={{
              height: "100vh",
              // width: "20%",
              objectFit: "cover",
              // height: "100vh",
              // marginLeft: 10,
              // marginRight: 10,
              // userSelect: "none",
            }}
          /> */}

          {/* </QuickPinchZoom> */}
          <div
            onClick={() => {
              if (selectedImage < images.length) {
                setSelectedImage(selectedImage + 1);
              }
            }}
            style={{
              color: "#fff",
              cursor: "pointer",
              position: "absolute",
              marginLeft: "auto",
              zIndex: 100,
              right: 10,
            }}
          >
            <FaChevronRight size={30} />
          </div>
        </ModalContainer>
      </Modal>
      <Grid container>
        <Grid position={"relative"} item xs={12} sm={6} md={6}>
          <DotsContainer style={i18n.language === "ar" ? { right: 30 } : {}}>
            {images?.map((_, i) => (
              <>
                <Dot
                  onClick={() => {
                    let items =
                      imageContainerRef.current.querySelectorAll("img");

                    items[i].scrollIntoView({
                      behavior: "smooth",
                    });
                  }}
                  className={i === indexImage ? "active" : ""}
                />
              </>
            ))}
          </DotsContainer>
          <ImagesContainer ref={imageContainerRef}>
            {images?.map((imageSrc, i) => (
              <RenderImage
                key={i}
                i={i}
                imageContainerRef={imageContainerRef}
                imageSrc={imageSrc}
                handleClick={() => setSelectedImage(i + 1)}
                changeInViewPort={(i) => setIndexImage(i)}
              />
            ))}
          </ImagesContainer>
        </Grid>
        <Grid
          item
          xs={12}
          p={{ xs: 2, sm: 3, md: 7 }}
          mt={{ sm: 2, md: 10 }}
          sm={6}
          md={6}
        >
          <p className="products-websitename" style={{ fontSize: 22 }}>
            {data?.Parent?.website_name}
          </p>
          {i18n.language === "en"
            ? data?.Parent?.stock_en && (
                <p
                  className="products-price"
                  style={{ margin: 0, fontSize: 12, color: "red" }}
                >
                  Available in {data?.Parent?.stock_en}
                </p>
              )
            : data?.Parent?.stock_ar && (
                <p
                  className="products-price"
                  style={{ margin: 0, fontSize: 12, color: "red" }}
                >
                  متوفر في {data?.Parent?.stock_ar}
                </p>
              )}
          <LighText>
            {t`Code`}: {data?.Parent?.name}
          </LighText>
          <LighText>SKU: {SKU ?? data?.Parent?.sku}</LighText>
          {/* <p className="products-price">
            {data?.Parent?.price} {companyInformation?.currency}
          </p> */}

          {data?.Parent?.price_with_discount === data?.Parent?.price ||
          !data?.Parent?.price_with_discount ? (
            <p className="products-price">
              {data?.Parent?.price}{" "}
              {localStorage?.getItem("currency") ??
                companyInformation?.default_currency}
            </p>
          ) : (
            <PriceWithDiscountContainer>
              <LinedPrice>{data?.Parent?.price}</LinedPrice>
              <DiscountedPrice>
                {data?.Parent?.price_with_discount}{" "}
                {localStorage?.getItem("currency") ??
                  companyInformation?.default_currency}
              </DiscountedPrice>
              <DiscountPercentage>
                -{data?.Parent?.discount_percentage}%
              </DiscountPercentage>
            </PriceWithDiscountContainer>
          )}

          <p
            className="products-websitename"
            style={{
              color: "rgba(0,0,0,0.4)",
              fontSize: 12,
              fontWeight: 400,
              marginBottom: 10,
            }}
          >
            {i18n.language === "en"
              ? data?.Parent?.note_en
              : data?.Parent?.note_ar}
          </p>

          {data?.Parent?.show_tabby ? <div id="TabbyPromo"></div> : null}

          <SelectVariations>
            <Box p={2}>
              <p className="products-websitename">
                {t`Select color`}{" "}
                <SelectedColorSpan>{selectedColor}</SelectedColorSpan>
              </p>
              <Box
                display={"flex"}
                alignItems={"center"}
                gap={0.6}
                mt={2}
                mb={5}
              >
                {variations(data?.Products)?.map((variation) => (
                  <div
                    key={variation.id}
                    onClick={() => {
                      setSelectedSize(null);
                      setSKU(variation.sku);
                      setSelectedColor(variation?.color);
                      if (!sizes[0]?.size) {
                        setSelectedSize(variation?.id);
                      }
                      imageContainerRef.current.scrollTop = 0;
                    }}
                    style={{
                      width: "20px",
                      height: "20px",
                      border: "1px solid rgba(0,0,0, .2)",
                      cursor: "pointer",
                      backgroundColor: variation?.color_hex,
                      boxShadow:
                        selectedColor === variation?.color
                          ? `0 2px 5px 0 ${variation?.color_hex}`
                          : "none",
                    }}
                  />
                ))}
              </Box>

              {sizes && sizes[0]?.size && (
                <>
                  {selectedColor && (
                    <>
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                      >
                        <p className="products-websitename">{t`Select size`}</p>
                        <DetailsButton
                          onClick={() => {
                            if (data.MainCategoryName === "Shoes") {
                              setShoesZizeGuideOpen(true);
                            } else if (
                              !isNaN(sizes[0].size) &&
                              data.MainCategoryName !== "Shoes"
                            ) {
                              setSizeGuideOpen(true);
                            } else {
                              setInternationalZizeGuideOpen(true);
                            }
                            // if (
                            //   !isNaN(sizes[0].size) &&
                            //   data.MainCategoryName !== "Shoes"
                            // ) {
                            //   setInternationalZizeGuideOpen(true);
                            // } else if (!isNaN(sizes[0].size)) {
                            //   setShoesZizeGuideOpen(true);
                            // } else {
                            //   setSizeGuideOpen(true);
                            // }
                          }}
                        >{t`Size guide`}</DetailsButton>
                      </Box>
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        gap={0.6}
                        mt={-1}
                        ml={-1}
                      >
                        {sizes.map((variation) => (
                          <p
                            onClick={() => setSelectedSize(variation.id)}
                            style={{
                              cursor: "pointer",
                              border:
                                selectedSize === variation.id
                                  ? "1px solid black"
                                  : "none",
                            }}
                            className="products-size"
                          >
                            {variation.size}
                          </p>
                        ))}
                      </Box>
                    </>
                  )}
                </>
              )}
            </Box>
            <AddButton onClick={handleAddToCart} disabled={!selectedSize}>
              {addToCartStatus === "loading" ? (
                <CircularProgress style={{ color: "#fff" }} size={20} />
              ) : (
                t`Add to shopping bag`
              )}
            </AddButton>

            {/* {data?.Parent?.show_tabby && (
              <Box
                display={"flex"}
                flexDirection={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                mt={1}
                boxShadow={"0 2px 3px rgba(0,0,0,.3)"}
                borderRadius={1}
                paddingInline={2}
              >
                <p>{t`Pay in 4 interest-free installments`}</p>
                <img src={TabbyLogo} alt="Tabby" width={70} />
              </Box>
            )} */}

            <DetailsButton
              onClick={() => setOpenDetails(true)}
            >{t`Product Details`}</DetailsButton>
          </SelectVariations>
        </Grid>
      </Grid>

      <Container style={{ maxWidth: "87%" }}>
        <MoreText>{t`You may also like`}</MoreText>

        <Grid
          container
          spacing={2}
          // alignItems={"center"}
          justifyContent={"center"}
        >
          {Array.isArray(data?.AdditionalProducts) &&
            data?.AdditionalProducts?.map((product, index) => (
              <Grid item xs={6} md={3} key={product.id}>
                <ProductCard
                  addToCart={false}
                  product={product}
                  index={index}
                />
              </Grid>
            ))}
        </Grid>
      </Container>
    </>
  );
};

const RenderInsideImage = ({ src }) => {
  const imgRef = useRef();
  const onUpdate = useCallback(({ x, y, scale }) => {
    const { current: img } = imgRef;
    // check if image exists
    if (img) {
      const value = make3dTransformValue({ x, y, scale });
      img.style.setProperty("transform", value);
    }
  }, []);
  return (
    <QuickPinchZoom
      onUpdate={onUpdate}
      tapZoomFactor={2}
      zoomOutFactor={4}
      inertiaFriction={0}
      maxZoom={10}
      minZoom={1}
    >
      <img
        src={src}
        ref={imgRef}
        alt={``}
        // className="products-img"

        style={{
          height: "100vh",
          // width: "20%",
          objectFit: "cover",
          // height: "100vh",
          // marginLeft: 10,
          // marginRight: 10,
          // userSelect: "none",
        }}
      />
    </QuickPinchZoom>
  );
};

const RenderImage = ({
  i,
  handleClick,
  imageSrc,
  imageContainerRef,
  changeInViewPort,
}) => {
  const elementRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (elementRef.current && imageContainerRef.current) {
        // const rect = elementRef.current.getBoundingClientRect();
        // const isVisible =
        //   rect.top >= 0 &&
        //   rect.left >= 0 &&
        //   rect.bottom <=
        //     (window.innerHeight || document.documentElement.clientHeight) &&
        //   rect.right <=
        //     (window.innerWidth || document.documentElement.clientWidth);
        // setIsVisible(isVisible);
        // if (isVisible) {
        //   console.log("Visible");
        // }

        const rect = elementRef.current.getBoundingClientRect();
        const containerRect = imageContainerRef.current.getBoundingClientRect();
        const isVisble =
          rect.bottom <=
            (window.innerHeight || document.documentElement.clientHeight) &&
          rect.right <=
            (window.innerWidth || document.documentElement.clientWidth);
        if (isVisble) {
          changeInViewPort(i);
        }
      }
    };

    imageContainerRef.current.addEventListener("scroll", handleScroll);
    handleScroll();

    // return () => {
    //   imageContainerRef.current.removeEventListener("scroll", handleScroll);
    // };
  }, [changeInViewPort, i, imageContainerRef]);

  return (
    <img
      ref={elementRef}
      onClick={handleClick}
      src={imageSrc}
      alt={`product ${i}`}
      className="products-img"
    />
  );
};

export default SingleProductPage;
